export let apiController = new AbortController();

export const resetAbortController = () => {
    apiController = new AbortController();;
}
import security from './security';
import { configureStore } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const { getAccessTokenSilently, getLoginWithRedirect } = security;
import qs from "qs"
import { CreateDocumentBody, DeleteDocumentParams, DuplicateDocumentParams, FetchLibraryParams, LibraryRecord, LibraryResponse, UpdateDocumentParams } from './types/library';
import { ContentDownloadResponse, ContentTypesResponse } from './types/content';
import { AddContentToCollectionParams, AddContentToCollectionResponse, AddSourceToDocumentParams, AssociatedSourcesResponse, GetCollectionSourcesParams, GetDocumentSourcesParams, RemoveSourceFromDocumentParams } from './types/collection';
import { AgentBatchResponse, AgentCallIdsRequest, AgentPipeLineRequest as AgentPipeLineRequest, AgentPollingResponse, AgentRequest, AgentResponse, AgentSyncResponse, AsyncAgentResponse } from './types/agent';
import { ErrorMiddleware } from './ErrorMiddleware';
import { UnAuthMiddleware } from './UnAuthMiddleware';
import { UserbackMiddleware } from './UserBackMiddleware';
import {
    CreateInvitationsRequest,
    CreateInvitationsResponse,
    Organization,
    UpdateOrganizationRequest,
    UpdateUserRolesRequest,
    UserInfo,
    OrganizationInvitation,
    OrganizationMembersResponse,
    OrganizationRolesResponse,
} from './types/organization';
import { CodebaseVersionRequest, CodebaseVersionsResponse } from './types/codebase';

export const baseUrl = process.env.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_API_AUDIENCE;

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
        try {
            const token = await getAccessTokenSilently()();
            if (!token) {
                throw new Error('No authentication token available');
            }
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            return headers;
        } catch (error: any) {
            getLoginWithRedirect()();
            throw new Error('Authentication required');
        }
    },
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    }
});

export const driverApi = createApi({
    reducerPath: 'driverApi',
    baseQuery: baseQuery,
    tagTypes: [
        "Tags",
        "CodebaseVersions",
        "Library",
        "Content",
        "ContentTags",
        "ContentTypes",
        "DocumentSources",
        "Collections",
        "CobebaseRoot",
        "CollectionSources",
        "TagContents",
        "PDF",
        "OrganizationUsers",
        "Organizations",
        "OrganizationInvitations",
        "UserInfo",
    ],
    endpoints: (builder) => ({
        getLibrary: builder.query<LibraryResponse, FetchLibraryParams>({
            query: (search) => {
                return {
                    url: "/content/?latest_version_only=false",
                    params: search,
                }
            },
            providesTags: ["Library"]
        }),
        getContent: builder.query<LibraryRecord, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/content/${contentId}`,
                }
            },
            providesTags: ["Content"]
        }),
        getContentTags: builder.query<ContentTagsResponse, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/content/${contentId}/tags`,
                }
            },
            providesTags: ["ContentTags"]
        }),
        getContentDownloadUrl: builder.query<ContentDownloadResponse, { contentId: string }>({
            query: ({ contentId }) => {
                return {
                    url: `/content/${contentId}/download`,
                }
            },
            keepUnusedDataFor: 600,
            providesTags: ["PDF"]
        }),
        updateContent: builder.mutation<LibraryRecord, UpdateDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/`,
                    method: 'PUT',
                    body: {
                        content_name: params.name,
                        content: params.content,
                    },
                }
            },
            invalidatesTags: ["Content", "Library"]
        }),
        createDocument: builder.mutation<LibraryRecord, CreateDocumentBody>({
            query: (body) => {
                return {
                    url: "/content/",
                    method: "POST",
                    body,
                }
            },
            invalidatesTags: ["Library"]
        }),
        deleteDocument: builder.mutation<void, DeleteDocumentParams>({
            query: (params) => {
                return {
                    url: `/content/${params.id}/`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["Library"]
        }),
        getTags: builder.query<TagGetResponse, TagGetParams>({
            query: (params) => {
                const newParams = { ...params };
                if (params.name === "") {
                    delete newParams.name;
                }
                return {
                    url: "/tags/",
                    params: newParams,
                }
            },
            providesTags: ["Tags"]
        }),
        getTagContents: builder.query<LibraryResponse, { tagId: string }>({
            query: ({ tagId }) => {
                return {
                    url: `/tags/${tagId}/content`,
                }
            },
            providesTags: ["TagContents"]
        }),
        getContentTypes: builder.query<ContentTypesResponse, void>({
            query: () => "/content/types",
            providesTags: ["ContentTypes"]
        }),
        getCodebaseRoot: builder.query<LibraryRecord, { contentId: string }>({
            query: ({ contentId }) => `/content/${contentId}/codebase-root`,
            providesTags: ["CobebaseRoot"]
        }),
        postCallIdResults: builder.mutation<AgentBatchResponse, AgentCallIdsRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async/batch`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineAsync: builder.mutation<AsyncAgentResponse, AgentPipeLineRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/async`,
                    method: "POST",
                    body,
                }
            },
        }),
        postAgentPipelineSync: builder.mutation<AgentSyncResponse, AgentPipeLineRequest>({
            query: (body) => {
                return {
                    url: `/agent_pipelines/sync`,
                    method: "POST",
                    body,
                    signal: apiController.signal
                }
            },
        }),
        getAgentCallStatus: builder.query<AsyncAgentResponse, { callId: string }>({
            query: ({ callId }) => `/agent_pipelines/async/${callId}/`,
        }),
        createTag: builder.mutation<TagResult, Tag>({
            query(body) {
                return {
                    url: `/tags/`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ["Tags", "Library"],
        }),
        addContentToCollection: builder.mutation<AddContentToCollectionResponse, AddContentToCollectionParams>({
            query(params) {
                return {
                    url: `/tags/${params.collectionId}/content/${params.contentId}`,
                    method: 'POST',
                    body: {
                        include: true,
                    },
                }
            },
            invalidatesTags: ["DocumentSources", "TagContents"]
        }),
        removeContentFromCollection: builder.mutation<AddContentToCollectionResponse, AddContentToCollectionParams>({
            query(params) {
                return {
                    url: `/tags/${params.collectionId}/content/${params.contentId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["DocumentSources", "TagContents"]
        }),
        addSourceToDocument: builder.mutation<void, AddSourceToDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/document-sources/batch/`,
                    method: 'POST',
                    body: {
                        sources: params.sources,
                    },
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        removeSourceFromDocument: builder.mutation<void, RemoveSourceFromDocumentParams>({
            query(params) {
                return {
                    url: `/content/${params.contentId}/document-sources/batch/`,
                    method: 'DELETE',
                    body: {
                        source_ids: params.sourcesIds,
                    },
                }
            },
            invalidatesTags: ["DocumentSources"]
        }),
        getDocumentSources: builder.query<AssociatedSourcesResponse, GetDocumentSourcesParams>({
            query: ({ documentId }) => `/content/${documentId}/document-sources`,
            providesTags: ["DocumentSources"]
        }),
        getCollectionSources: builder.query<AssociatedSourcesResponse, GetCollectionSourcesParams>({
            query: ({ collectionId }) => `/tags/${collectionId}/content`,
            providesTags: ["CollectionSources"]
        }),
        addTagToContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/tags/${body.tagId}/content/${body.documentId}`,
                    method: 'POST',
                    body: {
                        include: false
                    }
                }
            },
            invalidatesTags: ["Library", "ContentTags"]
        }),
        updateTag: builder.mutation<void, UpdateTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'PUT',
                    body: params.tag,
                }
            },
            invalidatesTags: ["Tags", "Library", "ContentTags"]
        }),
        deleteTag: builder.mutation<void, DeleteTagParams>({
            query(params) {
                return {
                    url: `/tags/${params.tagId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Tags", "Library", "ContentTags"]
        }),
        removeTagFromContent: builder.mutation<void, AssociateTagWithContentParams>({
            query(body) {
                return {
                    url: `/tags/${body.tagId}/content/${body.documentId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ["Library", "ContentTags"]
        }),
        uploadCodebase: builder.mutation<LibraryRecord, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/codebase",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
        uploadPdf: builder.mutation<LibraryRecord, { file_path: string }>({
            query: ({ file_path }) => {
                return {
                    url: "/upload/pdf",
                    method: "POST",
                    body: { file_path },
                }
            },
            invalidatesTags: ["Library"]
        }),
        getCodebaseVersions: builder.query<CodebaseVersionsResponse, CodebaseVersionRequest>({
            query: ({ codebaseId, limit = 10, offset = 0 }) => `/codebases/${codebaseId}/versions?limit=${limit}&offset=${offset}`,
            providesTags: ['CodebaseVersions']
        }),
        getOrganizationUsers: builder.query<OrganizationMembersResponse, void>({
            query: () => '/organization/users',
            providesTags: ['OrganizationUsers']
        }),

        createOrganizationInvitations: builder.mutation<CreateInvitationsResponse, CreateInvitationsRequest>({
            query: (body) => ({
                url: '/organization/invitations',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['OrganizationInvitations']
        }),

        getUserOrganizations: builder.query<Organization[], void>({
            query: () => '/user/organizations',
            providesTags: ['Organizations']
        }),

        getOrganizationInvitations: builder.query<OrganizationInvitation[], void>({
            query: () => '/organization/invitations',
            providesTags: ['OrganizationInvitations']
        }),

        removeUserFromOrganization: builder.mutation<{ message: string }, string>({
            query: (uid) => ({
                url: `/organization/users/${uid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OrganizationUsers']
        }),

        deleteInvitation: builder.mutation<{ message: string }, string>({
            query: (invitationId) => ({
                url: `/organization/invitations/${invitationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OrganizationInvitations']
        }),

        updateOrganization: builder.mutation<Organization, UpdateOrganizationRequest>({
            query: (body) => ({
                url: '/organization',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Organizations']
        }),

        updateUserRoles: builder.mutation<void, { uid: string, roles: UpdateUserRolesRequest }>({
            query: ({ uid, roles }) => ({
                url: `/organization/users/${uid}/roles`,
                method: 'PUT',
                body: roles,
            }),
            invalidatesTags: ['OrganizationUsers']
        }),
        resetPassword: builder.mutation<{ message: string }, void>({
            query: () => ({
                url: '/user/password',
                method: 'PUT'
            })
        }),
        getUserInfo: builder.query<UserInfo, void>({
            query: () => ({
                url: `${process.env.NEXT_PUBLIC_AUTH_URL}/userinfo`,
            }),
            providesTags: ['UserInfo']
        }),
        getOrganizationRoles: builder.query<OrganizationRolesResponse, { page?: number; per_page?: number }>({
            query: (params) => ({
                url: '/organization/roles',
                params: {
                    page: params.page ?? 0,
                    per_page: params.per_page ?? 100,
                },
            }),
        }),
    }),
})


export const apiStore = configureStore({
    reducer: {
        [driverApi.reducerPath]: driverApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(driverApi.middleware)
        .concat(ErrorMiddleware)
        .concat(UnAuthMiddleware)
        .concat(UserbackMiddleware),
})

export const {
    useGetLibraryQuery,
    useGetTagsQuery,
    useGetDocumentSourcesQuery,
    useLazyGetCollectionSourcesQuery,
    useGetContentTypesQuery,
    useGetCodebaseRootQuery,
    useAddSourceToDocumentMutation,
    useAddContentToCollectionMutation,
    useRemoveSourceFromDocumentMutation,
    useCreateTagMutation,
    useAddTagToContentMutation,
    useUpdateTagMutation,
    useDeleteDocumentMutation,
    useGetContentTagsQuery,
    useGetTagContentsQuery,
    useRemoveContentFromCollectionMutation,
    usePostAgentPipelineSyncMutation,
    usePostAgentPipelineAsyncMutation,
    usePostCallIdResultsMutation,
    useLazyGetAgentCallStatusQuery,
    useRemoveTagFromContentMutation,
    useDeleteTagMutation,
    useCreateDocumentMutation,
    useUpdateContentMutation,
    useGetContentQuery,
    useUploadCodebaseMutation,
    useUploadPdfMutation,
    useGetContentDownloadUrlQuery,
    useGetCodebaseVersionsQuery,
    useGetOrganizationUsersQuery,
    useCreateOrganizationInvitationsMutation,
    useGetUserOrganizationsQuery,
    useGetOrganizationInvitationsQuery,
    useRemoveUserFromOrganizationMutation,
    useDeleteInvitationMutation,
    useUpdateOrganizationMutation,
    useUpdateUserRolesMutation,
    useResetPasswordMutation,
    useGetUserInfoQuery,
    useGetOrganizationRolesQuery,
} = driverApi
